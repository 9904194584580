import { Component, Vue, Prop } from 'vue-property-decorator';
import { User } from '@/models/User';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { ReportType } from '@/models/ReportType';

@Component<UserReportTypes>({})
export default class UserReportTypes extends Vue {
  @Prop()
  protected user!: User | null;

  @Prop()
  protected organization!: string;

  protected reportTypes: ReportType[] = [];

  protected reportTypeList: { [key: string]: boolean } = {};

  protected userReportTypes: ReportType[] = [];

  protected mounted() {
    this.initialize();
  }

  protected initialize() {
    if (! this.user) {
      return;
    }

    this.handleReportTypes();
  }

  protected addUserReportTypes() {
    if (! this.user) {
      return;
    }

    const selectedReportTypes = Object.keys(this.reportTypeList).filter((key: string) => this.reportTypeList[key]);

    this.user
      .dmz(this.organization)
      .AddReportTypes(this.user.uuid, { report_types: selectedReportTypes })
      .then(() => {})
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.handleReportTypes();
      });
  }

  protected handleReportTypes() {
    this.resetReportTypes();

    Promise.all([this.getReportTypes(), this.getUserReportTypes()])
      .then((results: [ReportType[] | AxiosError, ReportType[] | AxiosError]) => {
        this.reportTypes = (results[0] as ReportType[]);
        this.userReportTypes = results[1] as ReportType[];

        this.createReportTypesList();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected resetReportTypes() {
    this.userReportTypes = [];
    this.reportTypes = [];
    this.$set(this, 'reportTypeList', {});
  }

  protected getReportTypes(): Promise<ReportType[] | AxiosError> {
    return new ReportType()
      .all()
      .then((types: ReportType[]) => Promise.resolve(types))
      .catch((error: AxiosError) => Promise.resolve(error));
  }

  protected get isPlanner() {
    return this.$store.state.Auth.is_planner;
  }

  protected getUserReportTypes(): Promise<ReportType[] | AxiosError> {
    return new User()
      .dmz(this.organization)
      .getReportTypes(this.$route.params.id)
      .then((reportTypes: ReportType[]) => Promise.resolve(reportTypes))
      .catch((error: AxiosError) => Promise.resolve(error));
  }

  protected createReportTypesList() {
    if (! this.reportTypes) {
      return;
    }

    this.reportTypes.forEach((reportType: ReportType) => {
      if (reportType.uuid) {
        if (! this.reportTypeList[reportType.uuid]) {
          const reportTypeValue = this.userReportTypes.some((userReportType: ReportType) => userReportType.uuid === reportType.uuid);

          this.reportTypeList[reportType.uuid] = reportTypeValue;
        }
      }
    });
  }
}
