import { ExperienceSubmission } from '@/models/ExperienceSubmission';
import { Component, Watch } from 'vue-property-decorator';
import { User as UserModel, userTypes, getUserTypeName, UserType, userLevelLabels, Level, userRoles } from '@/models/User';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError, AxiosResponse } from 'axios';
import { cloneDeep, debounce } from 'lodash';
import { styles } from '@/support/Style';
import { Avatar } from '@/support/Avatar';
import { Address } from '@/models/Address';
import { ExperienceType } from '@/models/Experience';
import MiPassword, { MiPasswordVisibility, MiPasswordIcons } from '@/components/mi-password/MiPassword';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';
import WorkHours from '@/components/Availability/WorkHours/WorkHours.vue';
import { identifyHostname } from '@/support/Client';
import { PostalCode } from '@/models/PostalCode';
import UserAreas from '@/views/Users/UserAreas/UserAreas.vue';
import UserActions from '@/views/Users/UserActions/UserActions.vue';
import UserReportTypes from '@/views/Users/UserReportTypes/UserReportTypes.vue';
import PostalCodeExclusion from '@/views/Users/Expert/PostalCodeExclusion/PostalCodeExclusion.vue';
import ExpertRegister from '@/views/Users/ExpertRegister/ExpertRegister.vue';
import ExpertRegisterSubmissionsDatatable from '@/views/ExpertRegisterSubmissions/ExpertRegisterSubmissionsDatatable/ExpertRegisterSubmissionsDatatable.vue';
import UserCommunication from '@/views/Users/UserCommunication/UserCommunication.vue';
import SubmissionExperienceList from '@/views/ExpertRegisterSubmissions/SubmissionExperienceList/SubmissionExperienceList.vue';
import EnrollmentList from '@/views/Users/ExpertRegister/EnrollmentList/EnrollmentList.vue';
import { Options } from '@/components/mi-dialog/MiDialog';
import RejectSubmissionDialog from '@/views/ExpertRegisterSubmissions/RejectSubmissionDialog/RejectSubmissionDialog.vue';
import AbstractMoreInfoDialog from '@/components/dialog/more-info-dialog/AbstractMoreInfoDialog';
import { MoreInfo } from '@/components/dialog/more-info-dialog/MoreInfoDialog';
import { DashboardExpertRegisterInfo } from '@/support/Info';
import { ExperienceSubmissionItem } from '@/models/ExperienceSubmissionItem';
import HourTariffMatrix from '@/components/HourTariffMatrix/HourTariffMatrix.vue';
// import { TariffInfo, TariffUpdateMatrixPayload } from '@/components/HourTariffMatrix/HourPriceInput/HourPriceInput';
import { Rpc } from '@/models/Rpc';
import { ParsedTariffMatrix, parseMatrix, TariffMatrix } from '@/components/HourTariffMatrix/HourTariffMatrix';

@Component<User>({
  components: {
    WorkHours,
    PostalCodeExclusion,
    UserAreas,
    UserActions,
    UserReportTypes,
    ExpertRegister,
    ExpertRegisterSubmissionsDatatable,
    UserCommunication,
    SubmissionExperienceList,
    EnrollmentList,
    RejectSubmissionDialog,
    HourTariffMatrix,
  },
})
export default class User extends AbstractMoreInfoDialog {
  public $pageTitle = 'Deskundige';

  protected user: UserModel = new UserModel();

  protected userClone: UserModel | null = null;

  protected address: Address | null = null;

  protected experts: User[] = [];

  protected postalCodes: PostalCode[] = [];

  protected userPostalCodes: PostalCode[] = [];

  public oAuthServer: string = identifyHostname() || '';

  protected activeTab = 'tab-skills';

  protected userRoles = userRoles;

  protected organizationId: string | null = null;

  protected isEditingUser = false;

  protected isChangingPassword = false;

  protected isLoadingCountries = false;

  protected fullscreenInbox = false;

  protected isLoading = true;

  protected isUpdating = false;

  protected isSaved = false;

  protected isLoadingButton = false;

  protected isSavedButton = false;

  protected isFileUploaded = false;

  protected debouncedSearch: Function = debounce(this.handleSearch, 300);

  protected submission: ExperienceSubmission | null = null;

  protected isCreatingSubmission = false;

  protected isRejectingSubmission = false;

  protected isPasswordValid = false;

  protected moreInfo: MoreInfo[] = DashboardExpertRegisterInfo;

  protected matrix: TariffMatrix | null = null;

  protected parsedMatrix: ParsedTariffMatrix | null = null;

  protected visibility: MiPasswordVisibility = {
    ruleList: false,
    showPassword: false,
  };

  protected icons: MiPasswordIcons = {
    password: 'lock_open',
    confirmation: 'lock_open',
  };

  protected userTypes: UserType[] = userTypes;

  protected fileTypes: string[] = ['image/png', 'image/jpeg'];

  public async mounted() {
    this.$store.dispatch('changeBreadcrumbs', [{ name: 'Expert', path: '/expert-user' }]);
    await this.initialize();
    await this.getLatestSubmission();
    this.setActiveTab();
  }

  protected async getLatestSubmission() {
    const queryParams = this.$route.query;

    if (! queryParams.organization) {
      return;
    }

    if (! this.$store.state.Auth.has_expert_register) {
      return true;
    }

    const model = queryParams.organization ? new ExperienceSubmission().dmz(queryParams.organization as string) : new ExperienceSubmission();

    const submissions = await model
      .include(['user', 'items', 'declaration_of_expertise', 'declaration_of_good_behaviour', 'assessment'])
      .filter({
        user: this.user.uuid,
      })
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (submissions) {
      const submission = submissions.find((submission: ExperienceSubmission) => submission.status === 'pending');

      this.submission = submission || null;
    }
  }

  protected approveSubmission() {
    this.$store.dispatch('openDialog', this.approveSubmissionDialog);
  }

  protected openFileUrl(url: string) {
    if (! url) { return; }
    window.open(url, '_blank');
  }

  protected rejectSubmission() {
    this.isRejectingSubmission = true;
  }

  protected getLevelLabel(level: string) {
    return userLevelLabels[level];
  }

  protected setActiveTab() {
    this.activeTab = this.$route.query.tab ? `${this.$route.query.tab}` : this.activeTab;
  }

  public async initialize() {
    const queryParams = this.$route.query;

    if (! queryParams.organization) {
      return;
    }
    this.isLoading = true;
    this.submission = null;

    this.organizationId = queryParams.organization as string;
    await new UserModel()
      .dmz(this.organizationId)
      .include(['contact', 'address', 'country', 'roles', 'supervisor', 'experiences', 'fixed_skills', 'declaration_of_expertise', 'declaration_of_good_behaviour', 'verified_assessment'])
      .find(this.$route.params.id)
      .then((user: UserModel) => {
        this.user = user;

        this.isLoading = false;
        this.initBreadcrumb();
        this.getExperts();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected resetPostalCodes() {
    this.userPostalCodes = [];
    this.postalCodes = [];
  }

  protected handlePostalCodes() {
    this.resetPostalCodes();

    Promise.all([this.getPostalCodes(), this.getUserPostalCodes()])
      .then((results: [PostalCode[] | AxiosError, PostalCode[] | AxiosError]) => {
        this.postalCodes = results[0] as PostalCode[];
        this.userPostalCodes = results[1] as PostalCode[];
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getPostalCodes(): Promise<PostalCode[] | AxiosError> {
    return new PostalCode()
      .all()
      .then((postalCodes: PostalCode[]) => Promise.resolve(postalCodes))
      .catch((error: AxiosError) => Promise.resolve(error));
  }

  protected getUserPostalCodes(): Promise<PostalCode[] | AxiosError> {
    return new UserModel()
      .limit(300)
      .getPostalCodes(this.$route.params.id)
      .then((postalCodes: PostalCode[]) => Promise.resolve(postalCodes))
      .catch((error: AxiosError) => Promise.resolve(error));
  }

  protected initBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{
        name: 'Deskundige Gebruikersbeheer',
        path: '/instellingen/deskundige-gebruikersbeheer',
      }, { name: this.user.name }],
    });
  }

  protected handleSearch(query: any) {
    if (! query || ! query.target || ! query.target.value) {
      return;
    }

    new UserModel()
      .filter('search', query.target.value)
      .limit(500)
      .all()
      .then((response: any) => {
        this.experts = response;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get countries() {
    return this.$store.state.countries;
  }

  protected getExperts() {
    new UserModel()
      .all()
      .then((users: User[]) => {
        this.experts = users;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  private clearSaved() {
    setTimeout(() => {
      this.isUpdating = false;
      this.isSaved = false;
      this.isEditingUser = false;
    }, 1200);
  }

  protected resetUser() {
    if (! this.userClone) {
      return;
    }

    this.user = cloneDeep(this.userClone);
    this.userClone = null;

    this.isChangingPassword = false;
    this.resetMiPassword();
  }

  protected canSubmit(password: string, confirmation: string) {
    if (! password || ! confirmation) {
      return false;
    }
    return password === confirmation;
  }

  protected changeOrUpdatePassword() {
    if (this.isChangingPassword) {
      this.changePassword();
      this.isLoadingButton = true;
    } else {
      this.isChangingPassword = true;
    }
  }

  protected get canSavePassword() {
    if (! this.isChangingPassword) {
      return true;
    }

    if (this.isChangingPassword && this.isPasswordValid) {
      return true;
    }

    return false;
  }

  private changePassword() {
    const miPassword: MiPassword = this.$refs.miPassword as MiPassword;
    if (! miPassword) {
      return;
    }

    const password = miPassword.getPassword().password;
    const password_confirmation = miPassword.getPassword().confirmation;

    if (! this.canSubmit(password, password_confirmation)) {
      return;
    }

    this.isLoadingButton = true;
    this.user
      .update({ password, password_confirmation })
      .then(() => {
        this.isDelayingButtonFeedback();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected cancelEditPassword() {
    this.resetMiPassword();
    this.isChangingPassword = false;
  }

  private parseWebsiteUrl(url: string) {
    return ! url.includes('http://') && ! url.includes('https://') ? `https://${url}` : url;
  }

  protected handleUpdatedMatrix() {
    this.getTariffsMatrix();
  }

  protected async getTariffsMatrix() {
    if ((! this.organizationId || ! this.organizationId.length) && this.$store.state.isServiceOrganization) {
      return;
    }

    const response: null | AxiosResponse = await new Rpc()
      .dmz(this.organizationId ? this.organizationId : '')
      .rpcPost({
        signature: 'tariffs:get-matrix',
        body: {
          user_id: this.userId,
        },
      }, false);

    if (response && response.data) {
      this.matrix = response.data;
      this.parsedMatrix = parseMatrix(this.matrix as TariffMatrix);
    }
  }

  protected update() {
    this.user
      .update()
      .then(() => {
        this.isEditingUser = false;
        this.initialize();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected async updateSubmissionExperiences(experience: ExperienceSubmissionItem): Promise<void> {
    const result = await new ExperienceSubmissionItem({ id: experience?.id || '' })
      .dmz(this.organizationId as string)
      .update({ is_verified: !! experience?.is_verified });
  }

  protected uploadSignature(file: string) {
    if (file === null) {
      return;
    }

    this.isFileUploaded = true;
    const formData = new FormData();
    formData.append('signature', file);

    this.user
      .storeMedia(formData, this.user.uuid as string)
      .then(() => {
        const signatureUpload = this.$refs.signatureUpload as MiFileUpload;
        signatureUpload ? signatureUpload.uploaded() : null;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected isDelayingButtonFeedback() {
    setTimeout(() => {
      this.isLoadingButton = false;
      this.isSavedButton = true;
      this.isChangingPassword = false;

      setTimeout(() => {
        this.isSavedButton = false;
      }, 800);
    }, 1200);
  }

  protected get userType(): string {
    if (! this.user || ! this.user.type) {
      return '';
    }

    return getUserTypeName(this.user.type);
  }

  private resetMiPassword() {
    const miPassword: MiPassword = this.$refs.miPassword as MiPassword;
    if (miPassword) {
      miPassword.reset();
      miPassword.resetRules();
    }
  }

  get passwordButtonText() {
    if (this.isChangingPassword) {
      return this.$t('profile.password.save').toString();
    }

    if (this.isSavedButton) {
      return this.$t('profile.password.saved').toString();
    }

    return this.$t('profile.password.change').toString();
  }

  get passwordButtonIcon() {
    if (this.isChangingPassword) {
      return 'save';
    }
    if (this.isSavedButton) {
      return 'check';
    }
    return 'lock';
  }

  get passwordButtonColor() {
    if (this.isChangingPassword) {
      return 'primary';
    }
    if (this.isSavedButton) {
      return 'success';
    }
    return 'warning';
  }

  protected get userAvatar() {
    // const fullName = this.user.first_name + ' ' + this.user.last_name;
    return new Avatar(this.user.name ? this.user.name : 'NA', { color: styles.white, background: styles.primary, size: 512 }).toDataUrl();
  }

  protected get isAdmin() {
    // disable editing for everyone
    return false;
  }

  protected get isServiceOrganization() {
    return this.$store.state.isServiceOrganization;
  }

  protected get approveSubmissionDialog(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: 'Weet je zeker dat je deze aanvraag wilt goedkeuren',
      type: 'warning',
      buttons: {
        confirm: {
          text: 'Ja, goedkeuren',
          action: async () => {
            if (! this.submission || ! this.organizationId) { return; }
            const response = await this.submission.dmz(this.organizationId)
              .put({
                status: 'verified',
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
                return null;
              });

            if (response) {
              this.initialize();
              this.submission = null;
              this.$store.dispatch('openDialog', this.successDialogOptions);
            }
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {},
        },
      },
    };
  }

  protected get successDialogOptions(): Options {
    return {
      title: 'Aanvraag is goedgekeurd!',
      text: '',
      type: 'success',
      buttons: {
        confirm: {
          text: 'Ok',
          color: 'success',
          action: () => {
            this.$store.dispatch('closeDialog');
          },
        },
      },
    };
  }

  protected get userId(): string {
    return this.user && this.user.id ? this.user.id : '';
  }

  protected get isJunior() {
    return this.user?.level === Level.JUNIOR_DESKUNDIGE;
  }

  protected get showExperience() {
    if (! this.$store.state.Auth || ! this.user) {
      return false;
    } if (this.$store.state.Auth.id == this.user.id) {
      return true;
    }
    return ! this.$store.state.Auth.is_opnemer;
  }

  protected get ExperienceType() {
    return ExperienceType;
  }

  @Watch('isEditingUser')
  protected editingChanged(isEditing: boolean) {
    if (isEditing) {
      this.userClone = cloneDeep(this.user);
    }
  }

  @Watch('$route')
  public routeChanged(to: any, from: any) {
    this.initialize();
  }
}
