var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "grid-list-lg": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                {
                  class: { hide: _vm.fullscreenInbox },
                  attrs: { sm12: "", md5: "", lg4: "", xl3: "" }
                },
                [
                  _c(
                    "div",
                    { staticClass: "elementPanel profile" },
                    [
                      _vm.isLoading
                        ? _c("LoaderCard", {
                            attrs: { flat: "", type: "dots" }
                          })
                        : _vm._e(),
                      !_vm.isLoading
                        ? [
                            _c(
                              "v-layout",
                              {
                                staticClass: "profileContainer",
                                class: { editing: _vm.isEditingUser },
                                attrs: { row: "", wrap: "" }
                              },
                              [
                                !_vm.isEditingUser && _vm.isAdmin
                                  ? _c(
                                      "div",
                                      { staticClass: "iconContainer" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "iconContainer__edit"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "edit-icon",
                                                on: {
                                                  click: function($event) {
                                                    _vm.isEditingUser = true
                                                  }
                                                }
                                              },
                                              [_vm._v("edit")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.isEditingUser
                                  ? _c(
                                      "div",
                                      { staticClass: "iconContainer" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticClass: "cancel-icon",
                                            on: {
                                              click: function($event) {
                                                _vm.isEditingUser = false
                                                _vm.resetUser()
                                              }
                                            }
                                          },
                                          [_vm._v(" cancel ")]
                                        ),
                                        !_vm.isUpdating
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass: "save-icon",
                                                on: { click: _vm.update }
                                              },
                                              [_vm._v("save")]
                                            )
                                          : _vm._e(),
                                        _vm.isUpdating
                                          ? _c("LoaderCard", {
                                              staticClass: "spinner--inline",
                                              class: { checked: _vm.isSaved },
                                              attrs: { type: "spinner--small" }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("v-flex", { staticClass: "flex-starving" }, [
                                  _c("img", {
                                    staticClass: "profile__avatar",
                                    attrs: { src: _vm.userAvatar }
                                  })
                                ]),
                                _vm.user
                                  ? _c(
                                      "v-flex",
                                      [
                                        _vm.isEditingUser
                                          ? [
                                              _c("v-text-field", {
                                                attrs: { label: "Voornaam" },
                                                model: {
                                                  value: _vm.user.name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.user,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "user.name"
                                                }
                                              })
                                            ]
                                          : _vm._e(),
                                        !_vm.isEditingUser
                                          ? [
                                              _c(
                                                "h1",
                                                {
                                                  staticClass:
                                                    "profile__userName"
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "text-light"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.user.name)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.user
                              ? _c(
                                  "v-layout",
                                  {
                                    staticClass: "profileContainer",
                                    attrs: {
                                      row: "",
                                      wrap: "",
                                      "align-center": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { sm12: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            "prepend-icon": "alternate_email",
                                            label: "Email",
                                            value: _vm.user.email,
                                            disabled: !_vm.isEditingUser
                                          },
                                          model: {
                                            value: _vm.user.email,
                                            callback: function($$v) {
                                              _vm.$set(_vm.user, "email", $$v)
                                            },
                                            expression: "user.email"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { sm12: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            "prepend-icon": "mail",
                                            label: "Bedrijf mail",
                                            value: _vm.user.business_email,
                                            disabled: !_vm.isEditingUser
                                          },
                                          model: {
                                            value: _vm.user.business_email,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "business_email",
                                                $$v
                                              )
                                            },
                                            expression: "user.business_email"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { sm12: "" } },
                                      [
                                        _vm.isEditingUser
                                          ? _c("v-text-field", {
                                              attrs: {
                                                "prepend-icon": "local_phone",
                                                label: "Telefoonnummer",
                                                value: _vm.user.phone
                                              },
                                              model: {
                                                value: _vm.user.phone,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "phone",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.phone"
                                              }
                                            })
                                          : _vm._e(),
                                        !_vm.isEditingUser
                                          ? [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass:
                                                    "mr-2 disabledIcon"
                                                },
                                                [_vm._v("local_phone")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "disabledLabel"
                                                },
                                                [
                                                  _c("h4", [
                                                    _vm._v("Telefoonnummer")
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          "tel:" +
                                                          _vm.user.phone
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.user.phone)
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _vm.isEditingUser
                                      ? _c(
                                          "v-flex",
                                          { attrs: { sm12: "" } },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                "prepend-icon":
                                                  "assignment_ind",
                                                items: _vm.userTypes,
                                                "item-text": "name",
                                                "item-value": "key",
                                                label: "Type",
                                                "persistent-hint": "",
                                                "single-line": ""
                                              },
                                              model: {
                                                value: _vm.user.type,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "type",
                                                    $$v
                                                  )
                                                },
                                                expression: "user.type"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.address
                              ? _c(
                                  "v-layout",
                                  {
                                    staticClass: "profileContainer",
                                    attrs: {
                                      row: "",
                                      wrap: "",
                                      "align-center": ""
                                    }
                                  },
                                  [
                                    _c(
                                      "v-flex",
                                      { attrs: { sm6: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            "prepend-icon": "place",
                                            label:
                                              "" + _vm.$t("profile.street"),
                                            value: _vm.address.street,
                                            disabled: !_vm.isEditingUser
                                          },
                                          model: {
                                            value: _vm.address.street,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.address,
                                                "street",
                                                $$v
                                              )
                                            },
                                            expression: "address.street"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { sm3: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label:
                                              "" +
                                              _vm.$t("profile.housenumber"),
                                            value: _vm.address.number,
                                            disabled: !_vm.isEditingUser
                                          },
                                          model: {
                                            value: _vm.address.number,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.address,
                                                "number",
                                                $$v
                                              )
                                            },
                                            expression: "address.number"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { sm3: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label:
                                              "" + _vm.$t("profile.addition"),
                                            value: _vm.address.number_add,
                                            disabled: !_vm.isEditingUser
                                          },
                                          model: {
                                            value: _vm.address.number_add,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.address,
                                                "number_add",
                                                $$v
                                              )
                                            },
                                            expression: "address.number_add"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { sm6: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            "prepend-icon": "mail_outline",
                                            label:
                                              "" + _vm.$t("profile.postcode"),
                                            value: _vm.address.postcode,
                                            disabled: !_vm.isEditingUser
                                          },
                                          model: {
                                            value: _vm.address.postcode,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.address,
                                                "postcode",
                                                $$v
                                              )
                                            },
                                            expression: "address.postcode"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { sm6: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            "prepend-icon": "location_city",
                                            label: "" + _vm.$t("profile.city"),
                                            value: _vm.address.city,
                                            disabled: !_vm.isEditingUser
                                          },
                                          model: {
                                            value: _vm.address.city,
                                            callback: function($$v) {
                                              _vm.$set(_vm.address, "city", $$v)
                                            },
                                            expression: "address.city"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { sm12: "" } },
                                      [
                                        _c(
                                          "v-autocomplete",
                                          {
                                            attrs: {
                                              "prepend-icon": "public",
                                              loading: _vm.isLoadingCountries,
                                              items: _vm.countries,
                                              label: _vm.$t(
                                                "form.formFields.country.title"
                                              ),
                                              "item-text": "name",
                                              "item-value": "id",
                                              "persistent-hint": "",
                                              disabled: !_vm.isEditingUser
                                            },
                                            model: {
                                              value: _vm.address.country,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.address,
                                                  "country",
                                                  $$v
                                                )
                                              },
                                              expression: "address.country"
                                            }
                                          },
                                          [
                                            _c("v-slide-x-reverse-transition", {
                                              attrs: {
                                                slot: "append-outer",
                                                mode: "out-in"
                                              },
                                              slot: "append-outer"
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isEditingUser && _vm.isAdmin
                              ? _c(
                                  "v-layout",
                                  {
                                    staticClass:
                                      "profileContainer passwordContainer",
                                    attrs: {
                                      row: "",
                                      wrap: "",
                                      "align-center": ""
                                    }
                                  },
                                  [
                                    _vm.isChangingPassword
                                      ? [
                                          _c(
                                            "v-flex",
                                            { attrs: { sm12: "" } },
                                            [
                                              _c("MiPassword", {
                                                ref: "miPassword",
                                                attrs: {
                                                  visibility: _vm.visibility,
                                                  icons: _vm.icons
                                                },
                                                model: {
                                                  value: _vm.isPasswordValid,
                                                  callback: function($$v) {
                                                    _vm.isPasswordValid = $$v
                                                  },
                                                  expression: "isPasswordValid"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e(),
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          color: _vm.passwordButtonColor,
                                          icon: _vm.passwordButtonIcon,
                                          iconColor: "white",
                                          disabled: !_vm.canSavePassword
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.changeOrUpdatePassword(
                                              $event
                                            )
                                          }
                                        }
                                      },
                                      [
                                        !_vm.isLoadingButton
                                          ? [
                                              _vm._v(
                                                _vm._s(_vm.passwordButtonText)
                                              )
                                            ]
                                          : _vm._e(),
                                        _vm.isLoadingButton
                                          ? [
                                              _c("LoaderCard", {
                                                staticClass:
                                                  "spinner--inline button",
                                                attrs: {
                                                  type: "spinner--small"
                                                }
                                              })
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    ),
                                    _vm.isChangingPassword
                                      ? _c(
                                          "v-flex",
                                          { attrs: { "text-xs-right": "" } },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "bottom-link forgot",
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.cancelEditPassword(
                                                      $event
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("general.cancel")
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ]
              ),
              _vm.organizationId
                ? _c(
                    "v-flex",
                    { attrs: { sm12: "", md7: "", lg8: "", xl9: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticStyle: { position: "relative" },
                              attrs: { "pb-0": "" }
                            },
                            [
                              _c(
                                "v-tabs",
                                {
                                  attrs: { "slider-color": "secondary" },
                                  model: {
                                    value: _vm.activeTab,
                                    callback: function($$v) {
                                      _vm.activeTab = $$v
                                    },
                                    expression: "activeTab"
                                  }
                                },
                                [
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "tab__filled",
                                      class: {
                                        active:
                                          _vm.activeTab === "tab-communication"
                                      },
                                      attrs: { href: "#tab-communication" }
                                    },
                                    [_vm._v(" Communicatie ")]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "tab__filled",
                                      class: {
                                        active: _vm.activeTab === "tab-skills"
                                      },
                                      attrs: { href: "#tab-skills" }
                                    },
                                    [_vm._v(" Competenties ")]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "tab__filled",
                                      class: {
                                        active: _vm.activeTab === "tab-areas"
                                      },
                                      attrs: { href: "#tab-areas" }
                                    },
                                    [_vm._v(" Gebieden ")]
                                  ),
                                  _c(
                                    "v-tab",
                                    {
                                      staticClass: "tab__filled",
                                      class: {
                                        active:
                                          _vm.activeTab === "tab-report-types"
                                      },
                                      attrs: { href: "#tab-report-types" }
                                    },
                                    [_vm._v(" Rapport types ")]
                                  ),
                                  _vm.$store.state.Auth.has_expert_register
                                    ? [
                                        _c(
                                          "v-tab",
                                          {
                                            staticClass: "tab__filled",
                                            class: {
                                              active:
                                                _vm.activeTab ===
                                                "tab-deskundige-register"
                                            },
                                            attrs: {
                                              href: "#tab-deskundige-register"
                                            }
                                          },
                                          [_vm._v(" Deskundigenregister ")]
                                        ),
                                        _c(
                                          "v-tab",
                                          {
                                            staticClass: "tab__filled",
                                            class: {
                                              active:
                                                _vm.activeTab ===
                                                "tab-deskundige-register-submissions"
                                            },
                                            attrs: {
                                              href:
                                                "#tab-deskundige-register-submissions"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " Deskundigenregister aanvragen "
                                            )
                                          ]
                                        ),
                                        _vm.$store.state.Auth.is_rockefeller
                                          ? _c(
                                              "v-tab",
                                              {
                                                staticClass: "tab__filled",
                                                class: {
                                                  active:
                                                    _vm.activeTab ===
                                                    "tab-deskundige-tariffs"
                                                },
                                                attrs: {
                                                  href:
                                                    "#tab-deskundige-tariffs"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.getTariffsMatrix()
                                                  }
                                                }
                                              },
                                              [_vm._v(" Deskundigen Tarieven ")]
                                            )
                                          : _vm._e()
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c(
                                "v-tabs-items",
                                {
                                  model: {
                                    value: _vm.activeTab,
                                    callback: function($$v) {
                                      _vm.activeTab = $$v
                                    },
                                    expression: "activeTab"
                                  }
                                },
                                [
                                  _c(
                                    "v-tab-item",
                                    {
                                      attrs: {
                                        transition: false,
                                        "reverse-transition": false,
                                        lazy: true,
                                        value: "tab-communication"
                                      }
                                    },
                                    [
                                      _vm.activeTab === "tab-communication"
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "elementPanel elementPanel--user"
                                            },
                                            [
                                              _c("UserCommunication", {
                                                attrs: {
                                                  user: _vm.user,
                                                  organization:
                                                    _vm.organizationId
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    {
                                      attrs: {
                                        transition: false,
                                        "reverse-transition": false,
                                        lazy: true,
                                        value: "tab-skills"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementPanel elementPanel--user"
                                        },
                                        [
                                          !_vm.isLoading
                                            ? _c(
                                                "v-layout",
                                                {
                                                  attrs: { row: "", wrap: "" }
                                                },
                                                [
                                                  _c("Skills", {
                                                    attrs: {
                                                      type: "user",
                                                      model: _vm.user,
                                                      organization:
                                                        _vm.organizationId
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    {
                                      attrs: {
                                        transition: false,
                                        "reverse-transition": false,
                                        lazy: true,
                                        value: "tab-areas"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementPanel elementPanel--user"
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { sm12: "" } },
                                                [
                                                  _c("UserAreas", {
                                                    attrs: {
                                                      user: _vm.user,
                                                      organization:
                                                        _vm.organizationId
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c("hr", {
                                                staticClass:
                                                  "expert-user-divider"
                                              }),
                                              _c(
                                                "v-flex",
                                                { attrs: { sm12: "" } },
                                                [
                                                  _c("PostalCodeExclusion", {
                                                    attrs: {
                                                      user: _vm.user,
                                                      canEdit:
                                                        _vm.$store.state.Auth &&
                                                        _vm.$store.state.Auth
                                                          .is_planner,
                                                      organization:
                                                        _vm.organizationId
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    {
                                      attrs: {
                                        transition: false,
                                        "reverse-transition": false,
                                        lazy: true,
                                        value: "tab-report-types"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementPanel elementPanel--user"
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "", wrap: "" } },
                                            [
                                              _c("UserReportTypes", {
                                                attrs: {
                                                  user: _vm.user,
                                                  canEdit:
                                                    _vm.$store.state.Auth &&
                                                    _vm.$store.state.Auth
                                                      .is_planner,
                                                  organization:
                                                    _vm.organizationId
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    {
                                      attrs: {
                                        transition: false,
                                        "reverse-transition": false,
                                        lazy: true,
                                        value: "tab-deskundige-register"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "elementPanel elementPanel--user"
                                        },
                                        [
                                          _vm.isLoading
                                            ? _c("LoaderCard", {
                                                attrs: {
                                                  flat: "",
                                                  type: "spinner--center"
                                                }
                                              })
                                            : _vm._e(),
                                          !_vm.isLoading
                                            ? [
                                                _c("ExpertRegister", {
                                                  attrs: {
                                                    user: _vm.user,
                                                    organization:
                                                      _vm.organizationId,
                                                    readOnly: !_vm.$store.state
                                                      .Auth.has_expert_register
                                                  },
                                                  on: {
                                                    fetchExperiences:
                                                      _vm.initialize
                                                  }
                                                })
                                              ]
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-tab-item",
                                    {
                                      attrs: {
                                        transition: false,
                                        "reverse-transition": false,
                                        lazy: true,
                                        value:
                                          "tab-deskundige-register-submissions"
                                      }
                                    },
                                    [
                                      _c("div", [
                                        _vm.user && _vm.submission
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "pt-5 elementPanel elementPanel--user"
                                              },
                                              [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { wrap: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs12: "" } },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: { wrap: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs6: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "h2",
                                                                  {
                                                                    staticClass:
                                                                      "inline-block elementSubTitle"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Huidige Deskundigenregister"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "inline-block info-icon"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      {
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openReadMoreDialog(
                                                                                              "current_expert_register"
                                                                                            )
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        " info_outline "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          394219525
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "klik voor meer info"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs6: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "h2",
                                                                  {
                                                                    staticClass:
                                                                      "inline-block elementSubTitle"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Aanvraag"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "inline-block info-icon"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-tooltip",
                                                                      {
                                                                        attrs: {
                                                                          bottom:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "activator",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var on =
                                                                                  ref.on
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    _vm._g(
                                                                                      {
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.openReadMoreDialog(
                                                                                              "expert_register_submission"
                                                                                            )
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        " info_outline "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          false,
                                                                          1668088260
                                                                        )
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              "klik voor meer info"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs12: "" } },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: { wrap: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs6: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Niveau"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "value disabled"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "poll"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getLevelLabel(
                                                                            _vm
                                                                              .user
                                                                              .level
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs6: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Niveau"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "value disabled"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "poll"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.getLevelLabel(
                                                                            _vm
                                                                              .submission
                                                                              .level
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm.user
                                                      .started_working_at ||
                                                    _vm.submission
                                                      .started_working_at
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs6: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "label"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Werkzaam als schade-expert sinds"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "value disabled"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-1"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "today"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "dateFormat"
                                                                                )(
                                                                                  _vm
                                                                                    .user
                                                                                    .started_working_at
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs6: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "label"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Werkzaam als schade-expert sinds"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "value disabled"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          {
                                                                            staticClass:
                                                                              "mr-1"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "today"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm._f(
                                                                                  "dateFormat"
                                                                                )(
                                                                                  _vm
                                                                                    .submission
                                                                                    .started_working_at
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.user.postcode ||
                                                    _vm.submission.postcode
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs6: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "label"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Postcode"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "value disabled"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .user
                                                                                  .postcode
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs6: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "label"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Postcode"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "value disabled"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .submission
                                                                                  .postcode
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs12: "" } },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: { wrap: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs6: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Competenties"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "value disabled"
                                                                  },
                                                                  _vm._l(
                                                                    _vm.user
                                                                      .fixed_skills,
                                                                    function(
                                                                      skill,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "v-chip",
                                                                        {
                                                                          key:
                                                                            "skill-" +
                                                                            index,
                                                                          style:
                                                                            "background-color: " +
                                                                            skill.color +
                                                                            " !important"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  skill.name
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs6: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Competenties"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "value disabled"
                                                                  },
                                                                  _vm._l(
                                                                    _vm
                                                                      .submission
                                                                      .skills,
                                                                    function(
                                                                      skill,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "v-chip",
                                                                        {
                                                                          key:
                                                                            "submission-skill-" +
                                                                            index,
                                                                          style:
                                                                            "background-color: " +
                                                                            skill.color +
                                                                            " !important"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  skill.name
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { xs12: "" } },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: { wrap: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs6: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Beschikbaarheid in de FTE"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "value disabled"
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .user
                                                                            .available_fte
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  xs6: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "label"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Beschikbaarheid in de FTE"
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "value disabled"
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm
                                                                            .submission
                                                                            .available_fte
                                                                        )
                                                                      )
                                                                    ])
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm.showExperience
                                                      ? [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "EnrollmentList",
                                                                        {
                                                                          staticClass:
                                                                            "fill-height",
                                                                          attrs: {
                                                                            user:
                                                                              _vm.user,
                                                                            experiences:
                                                                              _vm
                                                                                .user
                                                                                .experiences,
                                                                            organization:
                                                                              _vm.organizationId,
                                                                            title:
                                                                              "Opleiding(en)",
                                                                            type:
                                                                              _vm
                                                                                .ExperienceType
                                                                                .SCHOOL,
                                                                            singular:
                                                                              "Opleiding",
                                                                            noResultsText:
                                                                              "Er zijn nog geen opleiding(en) toegevoegd",
                                                                            readOnly: true
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "SubmissionExperienceList",
                                                                        {
                                                                          staticClass:
                                                                            "fill-height",
                                                                          attrs: {
                                                                            readOnly: true,
                                                                            experienceSubmission:
                                                                              _vm.submission,
                                                                            title:
                                                                              "Opleiding(en)",
                                                                            type:
                                                                              _vm
                                                                                .ExperienceType
                                                                                .SCHOOL,
                                                                            singular:
                                                                              "Opleiding",
                                                                            noResultsText:
                                                                              "De opleiding(en) zijn niet gewijzigd"
                                                                          },
                                                                          on: {
                                                                            updateSubmissionExperiences:
                                                                              _vm.updateSubmissionExperiences
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "EnrollmentList",
                                                                        {
                                                                          staticClass:
                                                                            "fill-height",
                                                                          attrs: {
                                                                            user:
                                                                              _vm.user,
                                                                            experiences:
                                                                              _vm
                                                                                .user
                                                                                .experiences,
                                                                            organization:
                                                                              _vm.organizationId,
                                                                            title:
                                                                              "Werkervaring",
                                                                            type:
                                                                              _vm
                                                                                .ExperienceType
                                                                                .WORK,
                                                                            singular:
                                                                              "Ervaring",
                                                                            noResultsText:
                                                                              "Er is nog geen werkervaring toegevoegd",
                                                                            readOnly: true
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "SubmissionExperienceList",
                                                                        {
                                                                          staticClass:
                                                                            "fill-height",
                                                                          attrs: {
                                                                            readOnly: true,
                                                                            experienceSubmission:
                                                                              _vm.submission,
                                                                            title:
                                                                              "Werkervaring",
                                                                            type:
                                                                              _vm
                                                                                .ExperienceType
                                                                                .WORK,
                                                                            singular:
                                                                              "Ervaring",
                                                                            noResultsText:
                                                                              "De werkervaring is niet gewijzigd"
                                                                          },
                                                                          on: {
                                                                            updateSubmissionExperiences:
                                                                              _vm.updateSubmissionExperiences
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "EnrollmentList",
                                                                        {
                                                                          staticClass:
                                                                            "fill-height",
                                                                          attrs: {
                                                                            user:
                                                                              _vm.user,
                                                                            experiences:
                                                                              _vm
                                                                                .user
                                                                                .experiences,
                                                                            organization:
                                                                              _vm.organizationId,
                                                                            title:
                                                                              "Nevenfunctie(s)",
                                                                            type:
                                                                              _vm
                                                                                .ExperienceType
                                                                                .SIDE_ACTIVITY,
                                                                            singular:
                                                                              "Nevenfunctie",
                                                                            noResultsText:
                                                                              "Er zijn nog geen Nevenfunctie(s) toegevoegd",
                                                                            readOnly: true
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "SubmissionExperienceList",
                                                                        {
                                                                          staticClass:
                                                                            "fill-height",
                                                                          attrs: {
                                                                            readOnly: true,
                                                                            experienceSubmission:
                                                                              _vm.submission,
                                                                            title:
                                                                              "Nevenfunctie(s)",
                                                                            type:
                                                                              _vm
                                                                                .ExperienceType
                                                                                .SIDE_ACTIVITY,
                                                                            singular:
                                                                              "Nevenfunctie",
                                                                            noResultsText:
                                                                              "De Nevenfunctie(s) zijn niet gewijzigd"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              attrs: {
                                                                xs12: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "EnrollmentList",
                                                                        {
                                                                          staticClass:
                                                                            "fill-height",
                                                                          attrs: {
                                                                            user:
                                                                              _vm.user,
                                                                            experiences:
                                                                              _vm
                                                                                .user
                                                                                .experiences,
                                                                            organization:
                                                                              _vm.organizationId,
                                                                            title:
                                                                              "Bijzondere expertise(s)",
                                                                            type:
                                                                              _vm
                                                                                .ExperienceType
                                                                                .SPECIAL_EXPERTISE,
                                                                            singular:
                                                                              "Bijzondere expertise",
                                                                            noResultsText:
                                                                              "Er zijn nog geen Bijzondere expertise(s) toegevoegd",
                                                                            readOnly: true
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "SubmissionExperienceList",
                                                                        {
                                                                          staticClass:
                                                                            "fill-height",
                                                                          attrs: {
                                                                            readOnly: true,
                                                                            experienceSubmission:
                                                                              _vm.submission,
                                                                            title:
                                                                              "Bijzondere expertise(s)",
                                                                            type:
                                                                              _vm
                                                                                .ExperienceType
                                                                                .SPECIAL_EXPERTISE,
                                                                            singular:
                                                                              "Bijzondere expertise",
                                                                            noResultsText:
                                                                              "De Bijzondere expertise(s) zijn niet gewijzigd"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      : _vm._e(),
                                                    _vm.user &&
                                                    _vm.user
                                                      .declaration_of_expertise &&
                                                    _vm.user
                                                      .declaration_of_expertise
                                                      .original
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs6: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm.isJunior
                                                                      ? _c(
                                                                          "MiButton",
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "cloud_download",
                                                                              round:
                                                                                "true",
                                                                              outline:
                                                                                "true",
                                                                              background:
                                                                                "true"
                                                                            },
                                                                            nativeOn: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                return _vm.openFileUrl(
                                                                                  _vm
                                                                                    .user
                                                                                    .declaration_of_expertise
                                                                                    .original
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Verklaring van deskundigheid "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.submission &&
                                                                _vm.submission
                                                                  .declaration_of_expertise &&
                                                                _vm.submission
                                                                  .declaration_of_expertise
                                                                  .original
                                                                  ? _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs6:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-layout",
                                                                          {
                                                                            attrs: {
                                                                              wrap:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                attrs: {
                                                                                  xs6:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "MiButton",
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "cloud_download",
                                                                                      round:
                                                                                        "true",
                                                                                      outline:
                                                                                        "true",
                                                                                      background:
                                                                                        "true"
                                                                                    },
                                                                                    nativeOn: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.openFileUrl(
                                                                                          _vm
                                                                                            .submission
                                                                                            .declaration_of_expertise
                                                                                            .original
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " Verklaring van deskundigheid "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.user &&
                                                    _vm.user
                                                      .declaration_of_good_behaviour &&
                                                    _vm.user
                                                      .declaration_of_good_behaviour
                                                      .original
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs6: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "MiButton",
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "cloud_download",
                                                                          round:
                                                                            "true",
                                                                          outline:
                                                                            "true",
                                                                          background:
                                                                            "true"
                                                                        },
                                                                        nativeOn: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.openFileUrl(
                                                                              _vm
                                                                                .user
                                                                                .declaration_of_good_behaviour
                                                                                .original
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Verklaring omtrent gedrag "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm.submission &&
                                                                _vm.submission
                                                                  .declaration_of_good_behaviour &&
                                                                _vm.submission
                                                                  .declaration_of_good_behaviour
                                                                  .original
                                                                  ? _c(
                                                                      "v-flex",
                                                                      {
                                                                        attrs: {
                                                                          xs6:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-layout",
                                                                          {
                                                                            attrs: {
                                                                              wrap:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-flex",
                                                                              {
                                                                                attrs: {
                                                                                  xs6:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "MiButton",
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "cloud_download",
                                                                                      round:
                                                                                        "true",
                                                                                      outline:
                                                                                        "true",
                                                                                      background:
                                                                                        "true"
                                                                                    },
                                                                                    nativeOn: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.openFileUrl(
                                                                                          _vm
                                                                                            .submission
                                                                                            .declaration_of_good_behaviour
                                                                                            .original
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " Verklaring omtrent gedrag "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.submission &&
                                                    _vm.submission.assessment &&
                                                    _vm.submission.assessment
                                                      .original
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-flex", {
                                                                  attrs: {
                                                                    xs6: ""
                                                                  }
                                                                }),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs6: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "MiButton",
                                                                      {
                                                                        attrs: {
                                                                          icon:
                                                                            "cloud_download",
                                                                          round:
                                                                            "true",
                                                                          outline:
                                                                            "true",
                                                                          background:
                                                                            "true"
                                                                        },
                                                                        nativeOn: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.openFileUrl(
                                                                              _vm
                                                                                .submission
                                                                                .assessment
                                                                                .original
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Assessment "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.submission
                                                      .submission_notes
                                                      ? _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { xs12: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-layout",
                                                              {
                                                                attrs: {
                                                                  wrap: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-flex", {
                                                                  attrs: {
                                                                    xs6: ""
                                                                  }
                                                                }),
                                                                _c(
                                                                  "v-flex",
                                                                  {
                                                                    attrs: {
                                                                      xs6: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-textarea",
                                                                      {
                                                                        attrs: {
                                                                          disabled: true,
                                                                          label:
                                                                            "Wat is de reden van de aanvraag"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .submission
                                                                              .submission_notes,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.submission,
                                                                              "submission_notes",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "submission.submission_notes"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs12: "",
                                                          "mt-3": ""
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          {
                                                            attrs: {
                                                              wrap: "",
                                                              "justify-end": ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-flex",
                                                              {
                                                                attrs: {
                                                                  shrink: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "MiButton",
                                                                  {
                                                                    staticClass:
                                                                      "mr-3",
                                                                    attrs: {
                                                                      color:
                                                                        "warning",
                                                                      small:
                                                                        "true"
                                                                    },
                                                                    nativeOn: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.rejectSubmission(
                                                                          $event
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Afkeuren "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "MiButton",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "success",
                                                                      small:
                                                                        "true"
                                                                    },
                                                                    nativeOn: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.approveSubmission(
                                                                          $event
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Goedkeuren en overnemen "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "pt-5 elementPanel elementPanel--user"
                                          },
                                          [
                                            !_vm.isLoading
                                              ? [
                                                  _c(
                                                    "ExpertRegisterSubmissionsDatatable",
                                                    {
                                                      attrs: {
                                                        showFilters: true,
                                                        user: _vm.user,
                                                        organization:
                                                          _vm.organizationId
                                                      },
                                                      on: {
                                                        reload: _vm.initialize
                                                      }
                                                    }
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ])
                                    ]
                                  ),
                                  _vm.$store.state.Auth.is_rockefeller
                                    ? _c(
                                        "v-tab-item",
                                        {
                                          attrs: {
                                            transition: false,
                                            "reverse-transition": false,
                                            lazy: true,
                                            value: "tab-deskundige-tariffs"
                                          }
                                        },
                                        [
                                          _c("HourTariffMatrix", {
                                            attrs: {
                                              organizationId:
                                                _vm.organizationId,
                                              userId: _vm.userId
                                            },
                                            on: {
                                              updatedMatrix:
                                                _vm.handleUpdatedMatrix
                                            },
                                            model: {
                                              value: _vm.parsedMatrix,
                                              callback: function($$v) {
                                                _vm.parsedMatrix = $$v
                                              },
                                              expression: "parsedMatrix"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.isRejectingSubmission
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "700", persistent: true },
              model: {
                value: _vm.isRejectingSubmission,
                callback: function($$v) {
                  _vm.isRejectingSubmission = $$v
                },
                expression: "isRejectingSubmission"
              }
            },
            [
              _c("RejectSubmissionDialog", {
                attrs: {
                  submission: _vm.submission,
                  organization: _vm.organizationId
                },
                on: { submitted: _vm.initialize },
                model: {
                  value: _vm.isRejectingSubmission,
                  callback: function($$v) {
                    _vm.isRejectingSubmission = $$v
                  },
                  expression: "isRejectingSubmission"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isReadingMoreInfo
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.isReadingMoreInfo,
                callback: function($$v) {
                  _vm.isReadingMoreInfo = $$v
                },
                expression: "isReadingMoreInfo"
              }
            },
            [
              _c("MoreInfoDialog", {
                attrs: {
                  title: _vm.activeMoreInfo.title,
                  description: _vm.activeMoreInfo.description
                },
                model: {
                  value: _vm.isReadingMoreInfo,
                  callback: function($$v) {
                    _vm.isReadingMoreInfo = $$v
                  },
                  expression: "isReadingMoreInfo"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }