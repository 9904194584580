var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.reportTypes && _vm.reportTypes.length
        ? _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-layout",
                { staticClass: "item__list", attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { staticClass: "label", attrs: { xs12: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs10: "" } }, [
                            _c("span", [_vm._v("Naam")])
                          ]),
                          _c("v-flex", { attrs: { xs2: "" } }, [
                            _c("span", [_vm._v("Actief")])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.reportTypes, function(reportType, index) {
                    return _c(
                      "v-flex",
                      {
                        key: index,
                        staticClass: "list__item",
                        attrs: { xs12: "" }
                      },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "", "align-center": "" } },
                          [
                            _c("v-flex", { attrs: { xs10: "" } }, [
                              _c("span", [_vm._v(_vm._s(reportType.name))])
                            ]),
                            _c(
                              "v-flex",
                              { attrs: { xs2: "" } },
                              [
                                _vm.isPlanner
                                  ? _c("v-switch", {
                                      attrs: {
                                        color: "#837f16",
                                        "hide-details": ""
                                      },
                                      on: { change: _vm.addUserReportTypes },
                                      model: {
                                        value:
                                          _vm.reportTypeList[reportType.uuid],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.reportTypeList,
                                            reportType.uuid,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "reportTypeList[reportType.uuid]"
                                      }
                                    })
                                  : _c(
                                      "span",
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: _vm.reportTypeList[
                                                reportType.uuid
                                              ]
                                                ? "success"
                                                : "warning"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.reportTypeList[
                                                  reportType.uuid
                                                ]
                                                  ? "check"
                                                  : "close"
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }